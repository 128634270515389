import { useEffect } from 'react';

import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import CardRail from '../CardRail';
import ThemeCard from '../ThemeCard';

import type { ThemeCardProps } from '@skyscanner-internal/falcon-shared-types/types/ThemeCardProps';
import type { CityBreaksProps } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/CityBreaks';

const CityBreaks = ({ cards, description, title }: CityBreaksProps) => {
  const hasCards = !!cards?.length;

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.CITY_BREAKS,
      load_status: hasCards ? LOAD_STATUS.LOADED : LOAD_STATUS.INVALID,
    });
  }, [hasCards]);

  if (!hasCards) {
    return null;
  }

  const handleOnClickCard = (card: ThemeCardProps, index: number) => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.CITY_BREAKS,
      component_action: COMPONENT_ACTION.CITY_BREAKS.CARD_CLICKED,
      city_breaks: {
        card_clicked: {
          index,
          city: {
            location_id: card.entityId,
            location_name: card.title,
          },
          price: {
            amount: card.priceRaw?.amount,
            currency: card.priceRaw?.currencyCode,
            unit: card.priceRaw?.unit,
          },
        },
      },
    });
  };

  const handleOnClickPageIndicator = () => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.CITY_BREAKS,
      component_action: COMPONENT_ACTION.CITY_BREAKS.HRW_ARROW_CLICKED,
    });
  };

  return (
    <div className="CityBreaks">
      <BpkSectionHeader title={title} description={description} />

      <CardRail bottomNav onClickPageIndicator={handleOnClickPageIndicator}>
        {cards.map((card, index) => (
          <ThemeCard
            key={card.title}
            {...card}
            onClick={() => handleOnClickCard(card, index)}
          />
        ))}
      </CardRail>
    </div>
  );
};

export default CityBreaks;
